import {createSlice} from "@reduxjs/toolkit";

const dictionary = createSlice({
  name: 'dictionary',
  initialState: {
    cabins: [],
    brands: [],
    colors: [],
    passengerSeats: [],
    sleepCabinTypes: [],
    sleepCabinBeds: [],
    additionalEquipment: [],
  },
  reducers: {
    setCabins: (state, action) => {
      return {
        ...state,
        cabins: action.payload,
      };
    },
    setBrands: (state, action) => {
      return {
        ...state,
        brands: action.payload,
      };
    },
    setColors: (state, action) => {
      return {
        ...state,
        colors: action.payload,
      };
    },
    setPassengerSeats: (state, action) => {
      return {
        ...state,
        passengerSeats: action.payload,
      };
    },
    setSleepCabinTypes: (state, action) => {
      return {
        ...state,
        sleepCabinTypes: action.payload,
      };
    },
    setSleepCabinBeds: (state, action) => {
      return {
        ...state,
        sleepCabinBeds: action.payload,
      };
    },
    setAdditionalEquipment: (state, action) => {
      return {
        ...state,
        additionalEquipment: action.payload,
      };
    },
  }
})
export const {
  setCabins,
  setBrands,
  setColors,
  setPassengerSeats,
  setSleepCabinTypes,
  setSleepCabinBeds,
  setAdditionalEquipment
} = dictionary.actions;
export default dictionary.reducer
