import {createSlice} from "@reduxjs/toolkit";

const currentPage = createSlice({
  name: 'currentPage',
  initialState: {
    name: 'Lista zamówień',
  },
  reducers: {
    setName: (state, action) => {
      return {
        name: action.payload
      };
    },
    resetName: (state) => {
      return {
        name: 'Lista zamówień',
      }
    }
  }
})
export const { setName, resetName } = currentPage.actions;

export default currentPage.reducer
