import Axios from "axios";

const instance = Axios.create({
  baseURL: 'http://api.apk-wejkama.pl/',
});



if (sessionStorage.getItem('wejkamaAT')) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('wejkamaAT')}`;
}

instance.interceptors.request.use(function (config) {
  if (sessionStorage.getItem('wejkamaAT')) {
    config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('wejkamaAT')}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => response, (error) => {
  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 401) {
    sessionStorage.removeItem('wejkamaAT')
  }
  // return Promise.reject(error);
  throw error;
});

const callAPI = async ({ url, method, data, params }) => {
  return instance({
    url,
    method,
    data,
    params
  });
};

const statusList = [0, 1, 2, 3]

const status = (number) => {
  const data = {
    0: 'nowe zamówienie',
    1: 'w trakcie realizacji',
    2: 'zakończone',
    3: 'anulowane'
  }
  return data[number]
}

const statusColor = (number) => {
  const color = {
    0: 'black',
    1: 'black',
    2: 'white',
    3: 'white'
  }
  return color[number]
}

const statusVariant = (number) => {
  const color = {
    0: 'yellow',
    1: 'orange',
    2: 'green',
    3: 'red'
  }
  return color[number]
}

const dateInfo = (datedata, isTime = true, sep = '-') => {
  if (datedata === null) {
    return ''
  }
  const date = new Date(datedata)
  if (isTime) {
    return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}-${date.getFullYear()} 
      ${date.getHours()}:${date.getMinutes()}`
  } else {
    return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}${sep}${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}${sep}${date.getFullYear()}`
  }

}

export { callAPI, status, statusColor, statusVariant, dateInfo, statusList }