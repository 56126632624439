import { all } from "redux-saga/effects";
import {userSaga} from "./user";
import {dictionarySaga} from "./dictionary";

export default function* rootSaga() {
  yield all([
      userSaga(),
    dictionarySaga()
    ]
  );
}
